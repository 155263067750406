@import "flexbox";
@import "variables";

form{
  max-width: 300px;
  padding: 1px 40px 20px;
  background: white;
  margin: 0 auto;

  ul {
    padding: 0;
    li {
      list-style: none;
      padding : 10px;
      background: #d97817;
    }
  }

  label {
    margin: 40px 0 10px;
    font-weight: bold;
    display: block;
  }

  input, textarea, button {
    color: $primaryColor;
  }

  input[type=email], input[type=text], input[type=password] {
    margin-bottom: 20px;
    display: block;
    background: none;
    border: none;
    border-bottom: 1px solid $primaryColor;
    padding: 10px 0 2px;
    width: 100%;
  }

  textarea{
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    padding: 10px;
    border: 1px solid $primaryColor;
  }

  input[type=checkbox] {
    margin-right: 10px;

    & + label {
      font-weight: normal;
    }
  }

  input[type=submit], button {
    margin: 40px 0 20px;
    display: block;
    width: 100%;
    background: transparent;
    padding: 10px;
    border: 1px solid $primaryColor;
    font-weight: bold;
    font-size: 18px;
  }
}