@import "variables";

// Donut Chart Mixin
.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      text-align: center;
    }
  }
}

@mixin donut-chart($name, $perc, $size, $width, $base, $center, $color, $textColor: $color, $textSize: 40px) {

  $percDiz: calc( $perc / 10);
  $sizeDemi: calc( $size / 2);
  $color2: $color;
  $base2: $base;
  $deg: ( calc( $perc/100 )*360 )+deg;
  $deg1: 90deg;
  $deg2: $deg;
  $keyframe-donut: 'donut-anim-' + $perc;

  // If percentage is less than 50%
  @if $perc < 50 {
    $base: $color;
    $color: $base2;
    $color2: $base2;
    $deg1: ( calc( $perc/100 )*360+90 )+deg;
    $deg2: 0deg;
  }

  .donut-chart {
    margin: 0 auto;

    &#{$name} {
      width: $size;
      height: $size;
      background: $base;

      .slice {
        &.one {
          clip: rect(0 $size $sizeDemi 0);
          -webkit-transform: rotate($deg1);
          transform: rotate($deg1);
          background: $color;
        }

        &.two {
          clip: rect(0 $sizeDemi $size 0);
          -webkit-transform: rotate($deg2);
          transform: rotate($deg2);
          background: $color2;
        }
      }

      .chart-center {
        top: $width;
        left: $width;
        width: $size - ($width * 2);
        height: $size - ($width * 2);
        background: $center;

        span {
          font-size: $textSize;
          line-height: $size - ($width * 2);
          color: $textColor;
          font-weight: bold;

          &:after {
            content: '#{$percDiz}';
          }
        }
      }
    }
  }
} // mixin