$brand-primary: #337ab7;
$brand-success: #4169e1;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

$white: #fff;
$darkGrey: #333;
$lightGrey: #ddd;
$beige: #f5f5f5;

$primaryColor: #4169e1;
$secondaryColor: #eee;
$thirdColor: #333;

$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

$pageWidth: 1200px;
$tabletWidth: 940px;
$mobileWidth: 680px;
$headerHeight: 0px;
$footerHeight: 30px;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-xs-max: $screen-sm;
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-sm-max: $screen-md;
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-md-max: $screen-lg;