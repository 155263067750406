@import "modules/flexbox";
@import "modules/variables";
@import "modules/donut";
@import "modules/form";


$pageWidth: 1200px;
$tabletWidth: 940px;
$mobileWidth: 680px;
$headerHeight: 0px;
$footerHeight: 30px;

$nameWidth: 300px;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-xs-max: $screen-sm;
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-sm-max: $screen-md;
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-md-max: $screen-lg;

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;

  font-family: Verdana, Geneva, DejaVuSans, sans-serif;
  background-color: #fff;
}

html, body, a {
  color: #333;
}

ul {
  margin: 0;
  padding: 10px 40px 20px;
  display: block;
  width: 100%;
}

li {
  list-style: none;
  display: inline-block;
}

h1 {
  margin: 0;
  padding: 20px 0;
}

h2 {
  margin: 0;
  padding: 20px;
}

.section h2 {
  padding-top: 60px;
}

h4 {
  margin: 0;
  padding: 10px 0;
}

.text-center {
  text-align: center;
}

#me, #subtitle, #attributes, #skills, #hobbies, #links, #projects {
  background: #f5f5f5;
}


#me .name, #me .job, #delimiter span {
  height: 41px;
}

#me {
  color: $primaryColor;
  padding-left: 15px;
  padding-right: 15px;

  #name {
    text-align: center;
    overflow: hidden;

    .name, .job {
      display: block;
    }

    .name {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 34px;
    }

    .job {
      font-size: 30px;
      font-weight: normal;
      margin-top: 10px;
    }

    white-space: nowrap;
    overflow: hidden;
  }

  #quote {
    margin-top: 30px;
    margin-left: 80px;

    .quote {
      font-size: 14px;
      font-style: italic;
    }

    i.fa-quote-left {
      margin-left: -26px;
      margin-top: -14px;
      float: left;
    }

    i.fa-quote-right {
      float: right;
      margin-right: -26px;
      margin-top: 30px;
    }

    i {
      font-size: 44px;
      line-height: 14px;
      font-weight: bold;
    }


    @media (max-width: 767px) {
      padding: 20px 40px;
      margin: 0;
    }
  }

  #delimiter {
    width: 0;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      display: inline-block;
      animation: blink 1s infinite;

      width: 0;
      border-right: 1px solid;
    }
  }
}

#attributes {
  @include flexbox();
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);

  li {

    &:not(.separator) {
      margin: 0 20px;
    }

    &.separator {
      background: #333;
      width: 5px;
      height: 5px;
      border-radius: 5px;
    }

    @media (max-width: 767px) {
    }
  }
}

#skills {
  li {
    margin: 0 40px;
    padding: 20px 0;

    .name {
      font-weight: bold;
      margin-top: 10px;
    }
  }
}

#experiences {
  .description {
    font-size: 14px;
  }
}

#experiences, #educations, #projects {
  &:not(.html-pdf) {
    ul {
      @include inline-flex();
      @include flex-flow(row wrap);
      @include align-items(flex-start);
      @include justify-content(space-between);

      li {
        width: 50%;
        min-width: 400px;
      }
    }
  }

  ul {
    li {
      display: block;
      text-align: left;
      padding: 20px 40px;
    }
  }

  li {
    page-break-inside: avoid;
  }
}

#projects {
  .title {

  }

  .description {
    margin: 10px 0;
  }
}

#hobbies {
  padding-bottom: 40px;

  ul {
    padding: 0 10px 10px;
  }
}

#links {
  padding: 60px 0 20px;

  ul {
    @include inline-flex();
    @include flex-flow(row wrap);
    @include align-items(flex-start);
    @include justify-content(center);

    li {
      margin: 0 20px;
    }
  }
}

form {
  max-width: 500px !important;
}

#download {
  position: fixed;
  right: 0;
  z-index: 9999;
  top: 0;

  padding: 0 8px 5px 8px;
  font-size: 30px;

  line-height: 1.33333;
  border-radius: 0;

  color: #fff;
  background-color: $brand-success;
  border: 1px solid $brand-success;

  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 13px 55px;
  min-height: 46px;
  background: #555;
  color: #eee;
  text-align: center;
  z-index: 100;


  &, button, a {
    font-weight: bold;
    color: #eee;
  }

  .close {
    background: transparent;
    border: 0;

    position: absolute;
    left: 0;
    top: 0;

    font-size: 36px;
    padding: 2px 10px;

    text-decoration: none;
  }
}

#message + #me {
  padding-top: 46px;
}

@media (min-width: $screen-sm-min) {
  #me {

    #name, #quote {
      float: none;
      display: inline-block;
    }

    #name {
      text-align: right;
      width: $nameWidth;
      max-width: $nameWidth;

      .name, .job {
        width: $nameWidth;
      }
    }


    #quote {
      max-width: 300px;
    }
  }
  #head:not(.html-pdf) #me {
    @include flexbox();
    @include flex-direction(row-reverse);
    @include justify-content(center);
    @include align-items(center);
    @include flex-wrap(wrap);

    height: 100vh;

    #name {
      animation: typing 5s steps(40, end);
    }
  }
}

@media (max-width: 767px) {
  #me #name .job {
    padding-left: 0;
  }
  .hidden-xs {
    display: none !important;
  }
  form {
    padding-right: 20px;
    padding-left: 20px;
  }
  #experiences, #educations, #projects {
    ul {
      padding-left: 0;
      padding-right: 0;

      li {
        width: 100% !important;
        min-width: auto !important;
      }
    }
  }
  #attributes {
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  #experiences ul li, #educations ul li, #projects ul li, form, #attributes, h2, #links ul {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 600px) {
  #attributes {
    display: block;
    text-align: center !important;
  }
}

.date {
  font-size: 14px;
  color: $brand-success;
  text-transform: uppercase;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777;
  border-radius: 0;
  margin: 10px 10px 0 0;
}

@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 100%
  }
}

@keyframes blink {
  to {
    opacity: .0;
  }
}

@for $i from 1 through 10 {
  @include donut-chart('.donut-' + $i, $i * 10, 80px, 15px, $lightGrey, $beige, $brand-success, $brand-success, 20px);
}

#skills:not(.html-pdf) {
  .bar-chart {
    position: relative;
    display: inline-block;
    height: 60px;
    width: 60px;
    background-color: $brand-success;

    div {
      background-color: $lightGrey;
    }

    span {
      font-weight: bold;
      display: block;
      line-height: 24px;
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

@mixin html-pdf() {
  .hidden-print {
    display: none !important;
  }
  .hidden-xs {
    display: none !important;
  }

  h1 {
    padding: 0;
  }
  h2 {
    padding-top: 40px !important;
    text-align: left;
    padding-left: 10px;
  }

  ul {
    padding-left: 0;
    padding-right: 0;
  }
  #me #name {
    &, div {
      text-align: center;
    }

    .job {
      padding-left: 0;
    }
  }
  #skills {
    display: none;
  }
  #attributes li:not(.separator) {
    margin: 0 10px;
    font-size: 12px;
  }
  #experiences ul li, #educations ul li, #projects ul li {
    padding: 20px 10px;
  }
  #me, #attributes, .section {
    background: #fff !important;
  }
  #quote i {
    display: none;
  }
  #hobbies {
    text-align: left !important;
  }
  a {
    text-decoration: none;
  }
}

.html-pdf {
  @include html-pdf();
  background: #fff !important;
  font-family: DejaVuSans, sans-serif;
}

@media print {
  @include html-pdf();
}
